header {
    position: fixed;
    z-index: 6;
}

.navbar {
    display: flex;
    justify-content: space-between;
    padding: 1.5em 10em;
    align-items: center;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: var(--box-shadow);
    width: 100vw;
    box-sizing: border-box;
    color: var(--text-color);
    position: relative;
    align-items: center;
}

.navbar h1 {
    margin: 0
}

.hamburguer {
    width: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
}

.hamburguer span {
    display: inline-block;
    width: 100%;
    height: 2.5px;
    background: var(--text-color);
    border-radius: 3px;
}

.hamburguer span:nth-child(2) {
    width: 85%;
    margin-left: auto;
    height: 2px;
}

.hamburguer.close span:nth-child(1) {
    animation: close-left .3s ease-in-out forwards;
}

.hamburguer.close span:nth-child(2) {
    animation: close-right .3s ease-in-out forwards;
}

.hamburguer.open span:nth-child(1) {
    animation: close-left-back .3s ease-in-out forwards;
}

.hamburguer.open span:nth-child(2) {
    animation: close-right-back .3s ease-in-out forwards;
}

.code,
.code * {
    font-family: 'Source Code Pro', sans-serif;
    letter-spacing: .07em;
    color: var(--text-color);
    text-transform: lowercase;
}

.code::before {
    content: '\3c';
    letter-spacing: .2em;
}

.code::after {
    content: '\3e';
    letter-spacing: .2em;
}

.autoclose::after {
    content: '/\3e';
}

.code::before,
.code::after {
    color: var(--primary);
    font-weight: bold;
}

.navbar .code:not(:last-of-type) {
    border-bottom: solid 1px var(--border-color);
}

.navbar .code:hover {
    background-color: var(--primary25);
}

h2.code {
    font-size: 2.3em;
    margin-bottom: 1.5em;
    width: 100%;
    position: relative;
    left: -2em;
    display: block;
}

.code.close {
    text-align: end;
    opacity: .3;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    left: 2em;
}

.code.close::before {
    content: '\3c/';
}

.code {
    display: block !important;
}

.logo{
    margin: auto 0;
}

@keyframes close-left {
    from {
        width: 100%;
        transform: rotate(0deg) translate(0, 0);
    }

    to {
        width: 85%;
        transform: rotate(45deg) translate(8px, 5px);
    }
}

@keyframes close-right {
    from {
        width: 100%;
        transform: rotate(0deg) translate(0, 0);
    }

    to {
        width: 85%;
        transform: rotate(-45deg) translate(0px, -5px);
    }
}

@keyframes close-left-back {
    from {
        transform: rotate(45deg) translate(8px, 5px);
    }

    to {
        transform: rotate(0deg) translate(0, 0);
    }
}

@keyframes close-right-back {

    from {
        transform: rotate(-45deg) translate(0px, -5px);
    }

    to {
        transform: rotate(0deg) translate(0, 0);
    }
}

.navbar ul.closed {
    position: absolute;
    animation: disappear .5s forwards ease;
}

.navbar ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    background-color: var(--bg-light-strong);
    box-shadow: var(--box-shadow);
    padding: 0;
    opacity: 0;
    z-index: 2;
    right: 10em;
    animation: visibility .01s forwards ease;
}

@keyframes visibility {
    from {
        visibility: visible;
    }

    to {
        visibility: hidden;
    }
}

.navbar ul li {
    padding: 1em 2.5em;
    transition: .3s;
}

.navbar ul a:not(:last-of-type) {
    border-bottom: solid 1px var(--bg-light);
}

.navbar ul.opened {
    animation: fade-up .5s forwards ease;
}

.theme-switcher {
    height: 20px;
    overflow: hidden;
}

.theme-switcher svg {
    width: 20px;
    height: 20px;
    object-fit: contain;
    display: block;
    transition: .5s;
}

.theme-switcher.dark svg {
    animation: dark .4s forwards ease;
}

.theme-switcher.light svg {
    animation: light .4s forwards ease;
}

.theme-switcher:hover svg {
    opacity: .8;
}

.sun {
    color: rgb(244 198 49);
}

@keyframes dark {
    from {
        transform: translateY(-20px);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes light {
    to {
        transform: translateY(-20px);
    }
}

@keyframes disappear {
    from {
        opacity: 1;
        transform: translateY(0%);
    }

    to {
        opacity: 0;
        transform: translateY(30%);
        visibility: hidden;
    }
}

.navbar ul li {
    list-style: none;
}

.menu-wrapper {
    display: flex;
    gap: 2em;
}

[role="button"] {
    cursor: pointer;
}

#language {
    border: none;
    padding: 0;
    padding-right: 15px;
    color: var(--text-color);
}

.icon-select {
    right: -.5em !important;
}

.language-switcher * {
    color: var(--text-color);
}

.fade-menu{
    z-index: -1 !important;
}

@media screen and (max-width: 1200px) {
    .navbar {
        padding: 1.5em 5em;
    }

    .navbar ul {
        right: 5em;
    }

    h2.code {
        font-size: 1.7em;
    }
}

@media screen and (max-width: 768px) {
    .navbar ul {
        width: 100vw;
        left: 0;
        /* gap: 15px; */
    }

    h2.code {
        font-size: 1.4em;
        left: -1em;
    }

    .code.close{
        right: -1em;
        left: initial;
    }

    .menu-wrapper{
        gap: 1.5em;
        align-items: center;
    }

    .hamburguer, .logo{
        transform: scale(.8);
    }

    .navbar {
        padding: 1em;
    }
}