.project{
    width: calc(100% / 3 - .7em);
    background: #fff;
    padding: 1.3em;
    box-sizing: border-box;
    border-radius: 5px;
    transition: .5s;
    border: solid 1px transparent;
    cursor: pointer;
    /* From https://css.glass */
    background: var(--bg-light-strong);
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    /* backdrop-filter: blur(5.4px); */
    /* -webkit-backdrop-filter: blur(5.4px); */
    border: 1px solid transparent;
}

.project:hover{
    border: solid 1px var(--border-color);
    /* box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.wrap-img-card{
    width: 100%;
    aspect-ratio: 1 / .7;
    height: auto;
    overflow: hidden;
    background-color: var(--bg-wrap-img-card);
}

.wrap-img-card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .3s;
    object-position: top;
}

.project:hover .wrap-img-card img{
    transform: scale(1.1);
}

.project h3{
    margin: .5em 0;
}

.project p{
    margin: 0;
}

.project .tags{
    margin: 1em 0;
    /* justify-content: center; */
}

.project .tag{
    padding: 5px 10px;
}

.card-footer{
    transition: .3s;
    overflow: hidden;
    height: 2.6em;
    line-height: 1.3em;
}

.card-footer span{
    display: inline-block;
    transition: .3s;
    height: 2.6em;
}

.project .subtitle{
    display: block;
}

.project:hover .subtitle, .project:hover .saiba-mais{
    transform: translateY(-2.3em);
}

.project:hover .subtitle{
    color: var(--bg-light-strong);
}

@media screen and (max-width: 768px) {
    .project{
        width: 110%;
    }

    .project .tags .tag:nth-child(3){
        display: none;
    }

    .project .saiba-mais{
        font-size: .9em;
    }

    .project .saiba-mais, .project .subtitle{
        transform: translateY(-2.3em);
    }

    .project .subtitle{
        opacity: 0;
    }

    .card-footer{
        transition: .3s;
        overflow: hidden;
        height: 2.5em;
        line-height: 1.3em;
    }
    
    .card-footer span{
        display: inline-block;
        transition: .3s;
        height: 2.5em;
    }
}