footer{
    background-color: var(--bg-wrap-img-card);
    position: relative;
    /* z-index: 5; */
    text-align: center;
    padding: 1.5em;
}

footer p{
    margin: 0;
    margin-bottom: .5em;
}

footer a{
    color: var(--primary);
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    footer{
        font-size: .6em;
        padding: 2em;
    }
}