.fixed{
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
    width: 100px;
    z-index: 3;
}

.fixed svg{
    font-size: 1.7em;
    color: var(--primary-transparency);
    transition: .3s;
}

.fixed a:hover svg{
    color: var(--primary);
    transform: translateY(-5px);
}

.fixed::after{
    content: '';
    width: 1.7px;
    height: 100px;
    background: var(--primary-transparency);
}