.fade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 7;
}

#modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    max-width: 90%;
    background-color: var(--bg-light-strong);
    padding: 3rem;
    border-radius: 5px;
    z-index: 8;
    max-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
}

#fade,
#modal {
    transition: 0.5s;
    opacity: 1;
    pointer-events: all;
}

#modal.hide,
#fade.hide {
    opacity: 0;
    pointer-events: none;
}

#modal.hide {
  top: 0;
}

.modal_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
}

.btn_close{
    color: var(--primary);
    font-size: 2em;
    cursor: pointer;
    margin-bottom: auto;
}

.modal_content{
    display: flex;
    gap: 1em;
    align-items: center;
}

.modal_content .column{
    width: 50%;
}

iframe, .img-project{
    width: 100%;
    aspect-ratio: 1 / .6;
    height: auto;
    border: 0;
}

.modal_content a:not(.btn){
    font-weight: bold;
    color: var(--primary-stronger);
    opacity: .9;
    transition: .3s;
    text-decoration: underline;
}

.modal_content a:hover{
    opacity: 1;
}

.involved a{
    text-decoration: none !important;
}

.involved strong{
    display: block;
    margin-bottom: .5em;
}

.modal_footer{
    display: flex;
    justify-content: flex-end;
    margin-top: .5em;
    gap: 1.5em;
}

#modal .subtitle{
    font-weight: bold;
    font-size: 1.1em;
}

.wrap-img-iframe{
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    aspect-ratio: 1 / .6;
    height: auto;
}

.wrap-img-iframe img{
    object-fit: cover;
    border-radius: 5px;
    height: 100%;
}

.wrap-play-icon{
    position: absolute;
    width: 2.5em;
    height: 2.5em;
    top: calc(50% - 1.5em);
    left: calc(50% - 1.5em );
    background-color: var(--primary);
    border-radius: 50%;
    padding: .5em;
}

.play-icon{
    color: #fff;
    font-size: 2.5em;
}

@media screen and (max-width: 768px){
    #modal{
        padding: 1.5em;
        width: 80% !important;
    }

    .modal_header h1{
        font-size: 1.8rem;
        line-height: normal;
    }

    .modal_content{
        flex-direction: column-reverse;
    }

    .modal_footer{
        flex-direction: column;
        gap: .7em;
    }

    iframe{
        aspect-ratio: 1 / .7;
    }
}