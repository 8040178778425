.stack:not(.selected):hover{
    border: solid 2px var(--primary-transparency);
}

.stack p{
    margin-bottom: 0;
    font-size: .7em;
}

.wrap-img-hab{
    width: 70%;
    aspect-ratio: 1;
    height: auto;
    display: flex;
    margin: auto;
}

.wrap-img-hab img, .wrap-img-hab svg{
    width: 100%;
    font-size: 3.5em;
    margin: auto;
    max-height: 100%;
    object-fit: contain;
}

.tooltip{
    /* position: absolute; */
    left: 10px;
    width: 160%;
    visibility: hidden;
    font-size: 1em;
    background: var(--primary-transparency);
    padding: 0 5px;
    color: var(--bg-light);
    border-radius: 3px;
    /* opacity: 0; */
    text-align: start;
    font-weight: bold;
    /* z-index: 2; */
}

.tooltip p{
    margin: .5em;
}

.stack {
    text-align: center;
    padding: 30px;
    position: relative;
    width: calc(100% / 3 - 2.5em);
    text-align: center;
    border: solid 2px transparent;
    padding:  .5em;
    border-radius: 3px;
    cursor: pointer;
    transition: .4s;
}

.stack:hover, .stack.selected{
    filter: none;
    border: solid 2px var(--primary);
    box-shadow: var(--shadow-secondary);
}

.tooltip{
    visibility: hidden;
    background-color: var(--secondary);
    padding: 5px;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    top: 90%;
    left: 10px;
    z-index: 1;
    line-height: normal;
    width: 160%;
    font-weight: bold;
}

.stack:hover span, .stack:hover .tooltip, .stack.selected .tooltip {
    animation: showUp .7s forwards ease;
}

@keyframes showUp {
    from{
        visibility: hidden;
        transform: translateY(20%);
    }
    to{
        visibility: visible;
        transform: translate(0);
    }
}

@keyframes show {
    from{
        transform: translateX(-50%);
    }
    to{
        opacity: 1;
    }
}

@keyframes hide {
    from{
        opacity: 1;
        transform: translateX(0%);
    }
    to{
        transform: translateX(-50%);
        opacity: 0;
        height: 0;
        padding: 0;
        visibility: hidden;
    }
}

@media screen and (max-width: 768px) {
    .stack{
        width: calc(100% / 3 - .5em);
        /* padding: .5em; */
        font-size: .8em;
        box-sizing: border-box;
    }

    .wrap-skills{
        gap: .1em !important;
    }

    .wrap-skills:not(:last-of-type){
        margin-bottom: 2em;
    }

    .wrap-skills.hab-see-less .skill:nth-child(n+7){
        animation: hide .7s forwards ease;
    }

    .wrap-skills h3{
        font-size: 1.3em;
    }
}