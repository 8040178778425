#resume{
    justify-content: center;
}

#page{
    width: calc(210mm + (210mm * 0.5));
    min-height: calc(297mm + (297mm * 0.5));
    background-color: var(--bg-light-strong);
    max-width: 100%;
    margin-bottom: 150px;
    padding: 4em;
    display: flex;
    gap: 2em;
    box-sizing: border-box;
}

#resume .row-btn{
    width: calc(210mm + (210mm * 0.5));
    max-width: 100%;
    margin-top: 200px;
    margin-bottom: 2em;
    justify-content: flex-end;
}

#resume .row-btn span{
    margin: auto 1.5em;
    height: fit-content;
}

.column-small{
    width: 30%;
}

.main-information{
    width: 70%;
}

#page p{
    margin-top: 0;
}

.with-icon{
    display: flex;
    gap: .5em;
    align-items: center;
    color: var(--text-color);
}

.with-icon img{
    width: 1em;
}

#page h1{
    font-size: 3em;
    margin-top: 0;
    line-height: normal;
    margin-bottom: .2em;
}

#page h2{
    margin-top: 0;
}

#page .btn-line::after{
    height: 2.5px;
    background: var(--primary-gradient);
}

.border-bottom, .education .timeline-item{
    padding-bottom: 1em !important;
    border-bottom: 1px solid var(--border-color);
}

#page h3{
    margin-top: 1em
}

#page .btn-line{
    background-image: var(--primary-gradient);
    background-size: 100%;
    background-repeat: repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

#page .timeline-marker{
    display: none;
}

#page .timeline-item{
    padding: 0;
    list-style: none;
    cursor: initial;
}

#page .timeline-content {
    opacity: 1;
    height: auto;
    visibility: visible;
    padding-bottom: 0;
}

#page small{
    font-size: 1rem;
}

#page .tags{
    margin-top: 1em;
}

#page ul{
    padding: 0 1em;
}

#page li:not(.timeline-item):not(:last-of-type){
    padding-bottom: .5em;
}

#resume .timeline-item:nth-of-type(2){
    padding-top: 1.5em !important;
}

.education .timeline-content small{
    display: none;
}

.education .timeline-item .timeline-info{
    padding-bottom: 0;
}

#page .btn{
    width: fit-content;
}

#page .btn:nth-of-type(2){
    margin-top: 1em;
}

#page .timeline-content li{
    font-size: small;
}

#page h4{
    margin-bottom: .5em;
}

.hard-skills{
    padding-bottom: 2em !important;
}

@media screen and (max-width: 768px){
    #page{
        flex-direction: column-reverse;
        padding: 2em;
    }

    #page h1{
        font-size: 2.5em;
        line-height: normal;
    }

    #page h3{
        font-size: 1.2em;
    }

    #resume .row-btn span{
        margin: 1em auto;
    }

    #resume .row-btn{
        margin-top: 10em;
    }
}