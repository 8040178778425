#skills{
    display: flex;
    flex-wrap: wrap;
    /* margin-bottom: 5em; */
    /* margin-top: -125px; */
    column-gap: 3em;
}

#skills h2{
    width: 100%;
    margin-bottom: 1em;
}

.wrap-skills{
    display: flex;
    gap: .8em;
    width: 30%;
    flex-wrap: wrap;
    justify-content: center;
}

.wrap-skills h3{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 2.8em;
    flex-direction: column;
    gap: .3em;
}
.show-up{
    opacity: 0;
    animation: grow .5s ease-in-out forwards;
}

.skill-info.show-up{
    animation: grow .5s ease-in-out forwards;
}

h3{
    font-size: 1.5em;
    margin-top: 0;
}

.skill-info .btn{
    margin-top: 2em;
}

@keyframes fade-up {
    from{
        opacity: 0;
        transform: translateY(30%);
        visibility: hidden;
    }
    to{
        opacity: 1;
        transform: translateY(0%);
        visibility: visible;
    }
}

@keyframes fade-up-skill {
    from{
        opacity: 0;
        transform: translateY(10%);
    }
    to{
        opacity: 1;
        transform: translateY(0%);
    }
}


@keyframes turn-up {
    from{
        transform: rotate(0deg);
    }

    to{
        transform: rotate(180deg);
    }
}

@keyframes turn-down {
    from{
        transform: rotate(180deg);
    }

    to{
        transform: rotate(0deg);
    }
}

@media screen and (max-width: 1200px) {
    #skills .column, .skill-info{
        width: 100%;
    }

    #skills{
        margin-top: 0;
    }
}

@media screen and (max-width: 768px) {
    .skill-info{
        display: none;
    }

    .timeline-marker{
        left: -1em;
        width: 30px;
    }

    .timeline-item .timeline-marker::before{
        left: -11px;
    }

    .timeline-icon{
        font-size: 20px;
        top: 10px;
        left: -4px;
    }

    .timeline-item{
        padding-left: 15px;
    }
}