#about {
  align-items: center;
}

#about .wrapper-pic {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#about .pic{
  aspect-ratio: unset;
  width: 80%;
}

#about img {
  width: 100%;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  #about {
    flex-direction: column-reverse;
    gap: 2em;
    font-size: .8em;
  }
}