#contact .pic{
    aspect-ratio: unset;
}

#contact {
    display: flex;
    height: 100vh;
    align-items: center;
    gap: 2em;
    justify-content: space-between;
    min-height: 500px;
    padding-top: 40px;
    color: var(--text-color);
}

form h1{
    font-size: 2.5em;
    margin-bottom: .5em;
    line-height: normal;
    margin-top: 0;
}

form p{
    font-size: 1.3em;
    margin-top: 0;
    line-height: normal;
}

input, textarea{
    outline: none;
    border: solid 1px var(--border-color);
    display: block;
    padding: .5em 1em;
    font-family: 'Manrope', 'Roboto', 'Oxygen', 'Cantarell', 'Helvetica Neue', sans-serif;
    margin-bottom: 1em;
    border-radius: 3px;
    box-sizing: border-box;
    width: 100%;
    margin-top: .5em;
    background: var(--bg-light-strong);
    transition: .5s;
    color: var(--text-color);
}

label:focus-within, label:hover{
    color: var(--primary);
}

textarea{
    resize: vertical;
}

input:focus, textarea:focus, 
label:hover input, label:hover textarea{
    border: solid 1px var(--primary);
}

label{
    font-size: .8em;
    margin-bottom: .5em;
    display: inline-block;
    width: 70%;
}

.required{
    color: rgb(255, 13, 13);
    display: inline-block;
    margin-left: 5px;
}

#contact .btn{
    min-width: 170px;
    text-transform: uppercase;
    gap: 15px;
}

@media screen and (max-width: 768px) {
    label{
        width: 100%;
    }

    #contact .wrapper-pic{
        display: none;
    }

    #contact{
        padding-top: 50px;
        height: calc(100vh - 50px);
    }

    #contact p{
        font-size: 1em;
    }

    #contact h1{
        font-size: 2em;
    }

    input, textarea{
        padding: .8em 1em;
        font-size: 1.1em;
    }
}